.donate {
  // General page styles
  padding-top: 0;

  &--title {
    margin-bottom: 64px;
    justify-content: center;

    @media (max-width: 768px) {
      margin-top: 80px;
    }

    @media (max-width: 375px) {
      margin-top: 40px;
      margin-bottom: 32px;
      font-size: 24px;
      line-height: 36px;
    }
  }

  .button {
    @media (max-width: 375px) {
      width: 100%;
      justify-content: center;
    }
  }

  // Tabs

  &--tab {
    cursor: pointer;
    text-transform: uppercase;
    font-family: 'Montserrat700', sans-serif;
    font-size: 16px;
    letter-spacing: 0.5px;
    color: rgba(21, 21, 21, 0.7);
    padding: 0 16px;
    outline: none;

    @media (max-width: 961px) {
      font-size: 15px;
    }

    @media (max-width: 768px) {
      font-size: 14px;
    }

    @media (max-width: 480px) {
      font-size: 12px;
      padding: 0 8px;
    }

    span {
      padding-bottom: 10px;
      border-bottom: 2px solid transparent;
      transition: border-bottom .2s;
    }

    &:hover {
      span {
        border-bottom: 2px solid rgba(21, 21, 21, 0.7);
      }
    }

    &.react-tabs__tab--selected {
      span {
        color: $primary;
        border-bottom: 2px solid $primary;
      }
    }
  }

  &--tabList {
    list-style-type: none;
    display: flex;
    justify-content: center;
    margin: 0 0 32px;
    padding: 0;

    @media (max-width: 768px) {
      margin-bottom: 36px;
    }
  }

  &--tabPanel {
    background: rgba(30, 115, 215, 0.1);
    border-radius: 5px;
    padding: 80px 100px;
    min-height: 300px;

    @media (max-width: 1199px) {
      padding: 80px;
    }

    @media (max-width: 768px) {
      background: transparent;
      padding: 0 !important;
    }

    &__1 {
      padding: 80px 200px;
      display: flex;
      justify-content: center;

      @media (max-width: 1199px) {
        padding: 80px;
      }
    }

    &__5 {
      padding: 80px 170px;

      @media (max-width: 1199px) {
        padding: 80px;
      }
    }
  }

  // Tab #1: Pay by card

  &--payByCard {
    display: flex;
    flex-direction: column;
    width: fit-content;

    @media (max-width: 768px) {
      width: 100%;
    }

    & > div {
      display: flex;
      column-gap: 44px;

      @media (max-width: 768px) {
        display: block;
      }
    }

    &Icon {
      min-width: 164px;
    }
  }

  &--wfpWrapper {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding-bottom: 32px;
    margin-bottom: 32px;

    .wfpImage {
      @media (max-width: 768px) {
        display: flex;
        justify-content: center;
        margin-bottom: 32px;
      }
    }

    .wfpDisclaimer {
      font-size: 14px;
      margin-top: 12px;
      line-height: 22px;
    }

    .button--wfp {
      display: inline-flex;
      align-items: center;
      justify-content: center;

      @media (max-width: 768px) {
        width: 100%;
      }

      @media (max-width: 375px) {
        min-width: 0;
      }

      img {
        margin-right: 12px;
      }
    }
  }

  &--cardWrapper {
    &:first-child {
      padding-bottom: 40px;
      margin-bottom: 40px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    }

    .cardImage {
      @media (max-width: 768px) {
        display: flex;
        justify-content: center;
        margin-bottom: 32px;
      }
    }

    p {
      margin-bottom: 24px;
      font-family: 'Montserrat500', sans-serif;
      font-size: 16px;
      line-height: 20px;

      @media (max-width: 768px) {
        text-align: center;
      }
    }

    .buttonWrapper {
      flex-grow: 1;
    }

    .button--card {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      img {
        margin-right: 12px;
      }

      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }

  &--safeTransfer {
    margin: 0 0 24px;
    display: flex;
    align-items: center;

    @media (max-width: 768px) {
      justify-content: center;
    }

    span {
      font-family: 'Montserrat500', sans-serif;
      font-size: 16px;
      line-height: 23px;
      margin-left: 12px;
    }

    img {
      max-height: 22px;
    }
  }

  // Tab #2: Within Ukraine

  &--withinUkraine {
    display: flex;
    column-gap: 80px;

    @media (max-width: 961px) {
      display: block;
    }

    & > div {
      flex-grow: 1;

      &:first-child {
        @media (max-width: 961px) {
          padding-bottom: 64px;
        }
      }
    }

    .h4 {
      padding-bottom: 16px;
      margin-bottom: 16px;

      &.kredo {
        border-bottom: 2px solid #e48f33;
      }

      &.privat {
        border-bottom: 2px solid #9ac356;
      }
    }

    p {
      line-height: 20px;
      margin-bottom: 0;

      span {
        font-family: 'Montserrat600', sans-serif;
      }
    }
  }

  &--infoLine {
    font-size: 16px;
    line-height: 20px;

    @media (max-width: 600px) {
      display: block;
      width: 100%;
    }

    p {
      &:first-child {
        margin-bottom: 4px;
      }
    }

    span {
      &:first-child {
        white-space: nowrap;
      }
    }

    .copyToClipboard {
      align-items: center;
      justify-content: space-between;

      @media (max-width: 600px) {
        margin-left: 0;
      }
    }
  }

  // Tab #3: Transfers from abroad

  &--transfersFromAbroad {
    display: flex;
    column-gap: 80px;

    @media (max-width: 961px) {
      display: block;
    }

    & > div {
      flex-grow: 1;

      @media (min-width: 962px) {
        width: calc(50% - 40px);
      }
    }

    .copyToClipboard {
      justify-content: space-between;
    }
  }

  // Tab #4: Paypal transfers

  &--paypal {
    display: flex;
    align-items: flex-start;
    column-gap: 44px;
    justify-content: center;

    p {
      margin-bottom: 0;
    }

    .copyToClipboard {
      align-items: center;
      margin-bottom: 20px;
    }
  }

  // Tab #5: Crypto transfers

  &--crypto {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 180px;
    row-gap: 90px;

    @media (max-width: 768px) {
      display: block;
    }

    & > div {
      display: flex;
      flex-direction: column;
      align-items: center;

      &:not(:last-child) {
        @media (max-width: 768px) {
          margin-bottom: 72px;
        }
      }
    }

    img {
      max-width: 180px;
    }

    p {
      margin: 24px 0 8px;
      line-height: 20px;
      font-family: 'Montserrat700', sans-serif;
      font-size: 18px;
    }

    .copyToClipboard {
      align-items: center;

      &--value {
        max-width: 250px;
        overflow-wrap: break-word;
      }
    }
  }
}
